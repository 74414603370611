import { getWebRTC } from "../../utils/webrtc/webrtcUtils";
import { getBrowserName } from "./getBrowser";

const LOG_PREFIX = "mediaStream: ";

const AUDIO_CONSTRAINT = {
  audio: true,
};

const getLegacyBrowserVideoContraints = (deviceId?: string) => {
  return {
    audio: true,
    video: { width: { exact: 1280 }, height: { exact: 720 }, deviceId },
  };
};

const getVideoCallExactConstraints = (deviceId?: string) => {
  return {
    audio: true,
    video: {
      deviceId,
      advanced: [
        {
          width: { exact: 1280 },
          height: { exact: 720 },
          frameRate: { exact: 30 },
        },
      ],
    },
  };
};

const getVideoCallIdealContraints = (deviceId?: string) => {
  return {
    audio: true,
    video: {
      deviceId,
      advanced: [
        {
          width: { ideal: 1280 },
          height: { ideal: 720 },
          frameRate: { ideal: 30 },
        },
      ],
    },
  };
};

let _audioStream: MediaStream | undefined;
export const getAudioStream = async () => {
  console.log(LOG_PREFIX, "getAudioStream");

  // Only audio, always release first in case previous set
  await releaseVideoCallStream();
  await releaseAudioStream();

  _audioStream = await navigator.mediaDevices
    .getUserMedia(AUDIO_CONSTRAINT)
    .catch((error) => {
      console.error(error);
      return undefined;
    });

  if (!_audioStream) {
    console.error(LOG_PREFIX, "Couldn't acquire audio stream");
    return;
  } else {
    console.log(LOG_PREFIX, "Acquired audio stream: ", _audioStream);
  }

  return _audioStream;
};

export const releaseAudioStream = async () => {
  console.log(LOG_PREFIX, "releaseAudioStream");
  try {
    _audioStream?.getTracks().forEach((track) => track.stop());
    _audioStream = undefined;
  } catch (e) {
    console.error(LOG_PREFIX, "Error releasing audio stream: ", e);
  }
};

const getVideoCallConstraints = (deviceId?: string) => {
  const isFirefox = getBrowserName(navigator.userAgent) === "Firefox";

  return !navigator.permissions
    ? getLegacyBrowserVideoContraints(deviceId)
    : isFirefox
      ? getVideoCallIdealContraints(deviceId)
      : getVideoCallExactConstraints(deviceId);
};

const getVideoStream = async (videoConstraints) => {
  return await navigator.mediaDevices
    .getUserMedia(videoConstraints)
    .catch((error) => {
      console.error(error);
      return undefined;
    });
};

let _videoCallStream: MediaStream | undefined;
export const getVideoCallStream = async (
  remoteVideoRef: any,
  outgoingVideoRef: any
) => {
  // Always release first in case previous set
  await releaseVideoCallStream();

  console.log(LOG_PREFIX, "getVideoCallStream");
  const globalWebRTC = getWebRTC();
  if (remoteVideoRef && globalWebRTC) {
    globalWebRTC.setVideoRef(remoteVideoRef);
  }
  const isFirefox = getBrowserName(navigator.userAgent) === "Firefox";

  /**
   * Handle single and multiple camera case:
   * 1 - Get the default video device first - this will also allow to enumerate all the devices available afterwards
   * 2 - Firefox directly handles which camera to use with permission prompt
   * 3 - For other browsers:
   *    a - enumerate the list of devices
   *    b - get and check if first device from the list (default one) is same as the one from 1
   *    c - if not, get new video from device got on b, fallback to default if not found
   */
  const vccDefault = getVideoCallConstraints();
  console.log(LOG_PREFIX, "Acquiring default video using ", vccDefault);
  _videoCallStream = await getVideoStream(vccDefault);

  if (!_videoCallStream) {
    console.error(LOG_PREFIX, "Couldn't acquire video stream");
    return undefined;
  }

  if (!isFirefox) {
    const devices = await navigator.mediaDevices.enumerateDevices();
    console.log(LOG_PREFIX, "Available devices: ", devices);

    const device = devices.find(
      (device) => device.kind === "videoinput" && device.deviceId
    );

    // First device from the list (which is the default one configured on browser or os) is not the same as the default one got from browser above (This will occur mostly on Chrome Mac, where the first and default are not the same), getting it
    if (
      device &&
      !_videoCallStream
        .getVideoTracks()
        .some((track) => track.label === device.label)
    ) {
      // Always release stream got before first
      await releaseVideoCallStream(false);
      const vcc = getVideoCallConstraints(device.deviceId);
      console.log(
        LOG_PREFIX,
        "Acquiring video from device ",
        device,
        " using ",
        vcc
      );

      _videoCallStream = await getVideoStream(vcc);

      if (!_videoCallStream) {
        console.log(
          LOG_PREFIX,
          "Cannot acquire video from device ",
          device,
          ", using default one"
        );
        _videoCallStream = await getVideoStream(vccDefault);
      }
    }
  }

  console.log(LOG_PREFIX, "Acquired video stream: ", _videoCallStream);

  if (outgoingVideoRef && outgoingVideoRef.current) {
    outgoingVideoRef.current.srcObject = _videoCallStream;
  } else {
    console.error(LOG_PREFIX, "Ref for outgoing video view is undefined");
  }

  return _videoCallStream;
};

export const releaseVideoCallStream = async (releaseRef = true) => {
  console.log(LOG_PREFIX, "releaseVideoCallStream");
  try {
    _videoCallStream?.getTracks().forEach((track) => track.stop());
    _videoCallStream = undefined;

    if (releaseRef) {
      const globalWebRTC = getWebRTC();
      if (globalWebRTC) {
        globalWebRTC.setAudioRef(undefined);
        globalWebRTC.setVideoRef(undefined);
      }
    }
  } catch (e) {
    console.error(LOG_PREFIX, "Error releasing video call stream: ", e);
  }
};
