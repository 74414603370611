import { isChatbot } from "@/utils/helpers/chatbots";
import { parsePhoneNumber, PhoneNumber } from "libphonenumber-js";

/**
 * Clean a phone number to only keep relevant information
 */
export function cleanPhoneNumber(phoneNumber?: string | null) {
  if (!phoneNumber) {
    return "";
  }

  if (
    isPhoneNumberAGroupContributionId(phoneNumber) ||
    isChatbot(phoneNumber)
  ) {
    return phoneNumber;
  }

  // Remove domain
  const lastAtIndex = phoneNumber.lastIndexOf("@");
  if (lastAtIndex !== -1) {
    phoneNumber = phoneNumber.substring(0, lastAtIndex);
  }

  // Keep relevant information only
  return phoneNumber.replace(/[^0-9+]/g, "");
}

export function isSamePhoneNumber(
  number1?: string | null,
  number2?: string | null
) {
  if (!number1 || !number2) return false;
  if (
    isPhoneNumberAGroupContributionId(number1) !==
    isPhoneNumberAGroupContributionId(number2)
  )
    return false;
  if (isChatbot(number1) !== isChatbot(number2)) return false;

  number1 = cleanPhoneNumber(number1);
  number2 = cleanPhoneNumber(number2);

  if (!number1 || !number2) return false;
  if (number1 === number2) return true;
  let parsed1: string | undefined;
  let parsed2: string | undefined;
  if (number1.startsWith("+")) {
    parsed1 = number1;
    parsed2 = number2;
  } else if (number2.startsWith("+")) {
    parsed1 = number2;
    parsed2 = number1;
  }

  // We need to parse with the country from the phone number that has all the information
  if (parsed1 && parsed2) {
    try {
      // Number has the + so no need to provide country information
      const parsedNumber1 = parsePhoneNumber(parsed1) as PhoneNumber;
      const parsedNumber2 = parsePhoneNumber(
        parsed2,
        parsedNumber1.country
      ) as PhoneNumber;

      // Check if numbers are now equal since we used the same country information
      return parsedNumber1.isEqual(parsedNumber2);
    } catch (e) {}
  }

  return false;
}
export function isPhoneNumberAGroupContributionId(
  phoneNumber?: string
): boolean {
  return (
    !isChatbot(phoneNumber) &&
    !!phoneNumber?.match(new RegExp("^" + REGEX_CONTRIBUTION_ID + "$"))
  );
} // We consider a group chat contribution id if contains at least one non digit character

export const REGEX_CONTRIBUTION_ID = "((?!(tel|sip|\\+)).*[a-zA-Z].*)";
export function getTextAfterLastSlash(str?: string) {
  return str?.substring(str.lastIndexOf("/") + 1) || "";
}
